import React, { useState, useEffect } from 'react';

import './assets/css/App.css';

import logo from './assets/images/logo.png';

import Main from './Main';

function App() {

    const [windowDimenion, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    })
    
    const detectSize = () => {
        detectHW({
          winWidth: window.innerWidth,
          winHeight: window.innerHeight,
        })
    }
    
    useEffect(() => {
        window.addEventListener('resize', detectSize)
        return () => {
          window.removeEventListener('resize', detectSize)
        }
    }, [windowDimenion]);

  return (
    <div className="App">
        <div className='App-Head'>
            <div style={{marginTop:"50px", marginLeft:windowDimenion.winWidth < 800 ? "5vw" : "25vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                <img src={logo} style={{width:"120px"}} alt=""/>
            </div>
        </div>
        <div className='App-Body'>
            <Main windowDimenion={windowDimenion}/>
        </div>
        <div className='App-Bottom' style={{ display:"flex" , flexDirection: "column" }}>
            <text style={{fontFamily:"Pretendard", fontSize:"1rem", textAlign:"center", color: "#fff"}}>
                Copyrightⓒ DKSOFT. All Rights Reserved.
            </text>
            <text style={{marginTop:'6px', fontFamily:"Pretendard", fontSize:"10px", textAlign:"center", color: "#fff"}}>
                상호명 : 주식회사 디케이소프트
            </text>
            <text style={{marginTop:'2px', fontFamily:"Pretendard", fontSize:"10px", textAlign:"center", color: "#fff"}}>
                대표이사: 이동규
            </text>
            <text style={{marginTop:'2px', fontFamily:"Pretendard", fontSize:"10px", textAlign:"center", color: "#fff"}}>
                사업자등록번호: 436-81-01755
            </text>
            <text style={{marginTop:'2px', fontFamily:"Pretendard", fontSize:"10px", textAlign:"center", color: "#fff"}}>
                {'주소: 경기도 성남시 분당구 황새울로258번길 35, 2층 213호 \n'}
            </text>
            <text style={{marginTop:'2px', fontFamily:"Pretendard", fontSize:"10px", textAlign:"center", color: "#fff"}}>
                연락처: 070-7700-3940
            </text>
            
        </div>
    </div>
  );
}

export default App;
