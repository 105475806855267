import React, { useState, useEffect } from 'react';
import { isMobile } from "react-device-detect";

import './assets/css/App.css';

import kv from './assets/images/kv.png';
import m_kv from './assets/images/m_kv.png';

import oaktree from './assets/images/oaktree.png';
import sangsang from './assets/images/sangsang.png';
import evbccs from './assets/images/evbccs.png';
import skyblue_poc from './assets/images/skyblue_poc.png';
import skyblue_dr from './assets/images/skyblue_dr.png';
import skyblue from './assets/images/skyblue.png';
import evening from './assets/images/evening.png';
import ulsan_energy from './assets/images/ulsan-energy.png';
import skyblue_character from './assets/images/skyblue_character.png';
import didim from './assets/images/didim.png';
import greenfoot from './assets/images/greenfoot.png';
import skyblue_smart from './assets/images/skyblue_smart.png';
import skyblue_public from './assets/images/skyblue_public.png';
import oaktree_2 from './assets/images/oaktree_2.png';
import multigym from './assets/images/multigym.png';

function Main(props) {

    const [isLoadedMainImage,setLoadedMainImage] = useState(false);

    useEffect(() => {
    }, []);

    const isMaxWindow = () => {
        return props.windowDimenion.winWidth >= 1600;
    }

    const isMiddleWindow = () => {
        return props.windowDimenion.winWidth < 1600 && props.windowDimenion.winWidth >= 800;
    }

    const isMinWindow = () => {
        return isMobile || props.windowDimenion.winWidth < 800;
    } 

    const RenderDashLine = () => {
        return (
            <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                <div style={{width:isMinWindow() ? "90vw" : "50vw", height:0, borderWidth:"0.5px",  borderStyle:"dashed", borderColor:"rgba(112,112,112,1)"}}/>
            </div>
        );
    }

    return (
        <>
            {/*main image*/}
            <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                <img src={isMobile ? m_kv : kv} style={{margin:"0 0 0 0", width:"100vw"}} alt="" onLoad={()=>setLoadedMainImage(true)}/>
            </div>
            
            {isLoadedMainImage ?
            <>
                {/*info*/}
                <div style={{position:"absolute", top:isMobile ? "180px" : isMinWindow() ? "90px" : "120px", left:isMinWindow() ? "5vw" : "25vw", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                    {isMobile ?
                    <text style={{fontFamily:"Pretendard", fontSize:"36px", textAlign:"left", color:"#fff"}}>
                        디케이소프트는<br/>크리에이티브 인사이트로<br/>디자인, 백엔드를 포함한<br/>플랫폼, 모바일 APP, WEB, 게임<br/>개발사입니다
                    </text>
                    :
                    <text style={{fontFamily:"Pretendard", fontSize:isMaxWindow() ? "46px" : isMiddleWindow() ? "36px" : "20px", textAlign:"left", color:"#fff"}}>
                        디케이소프트는 크리에이티브 인사이트로<br/>디자인 및 백엔드를 포함한<br/>플랫폼, 모바일 APP, WEB, 게임 개발사입니다
                    </text>
                    }
                </div>

                {/*about*/}
                <div style={{marginTop:"0px", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}>
                        <div style={{width:"100%", height:isMinWindow() ? "30px" : "40px", backgroundColor:"#fecc00"}}/>
                    </div>
                    <div style={{width:isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                        <text style={{marginLeft:"20px", height:isMinWindow() ? "30px" : "40px", fontFamily:"Pretendard", fontSize:isMinWindow() ? "28px" : "36px", textAlign:"center", color:"#000"}}>
                        About
                        </text>
                    </div>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>

                {/*about content*/}
                <div style={{marginTop:"100px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                법인명
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                주식회사 디케이소프트
                            </text>
                        </div>
                        <div style={{marginTop:"10px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                업태
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                정보통신업
                            </text>
                        </div>
                        <div style={{marginTop:"10px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                대표자
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                이동규
                            </text>
                        </div>
                        <div style={{marginTop:"10px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                회사주소
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                경기도 성남시 분당구 황새울로 258번길 35, 213호
                            </text>
                        </div>
                        <div style={{marginTop:"10px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                사업분야
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#34b8fd"}}>
                                EV충전 플랫폼. APP, WEB, 게임 개발. 캐릭터디자인.
                            </text>
                        </div>
                        <div style={{marginTop:"10px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                직원수
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                6명
                            </text>
                        </div>
                        <div style={{marginTop:"10px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                설립일
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                2020년 6월 2일
                            </text>
                        </div>
                        <div style={{marginTop:"10px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                전화
                            </text>
                            <text onClick={()=> { if(isMobile) window.location.href = "tel:070-7700-3940";}} style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                070-7700-3940
                            </text>
                        </div>
                        <div style={{marginTop:"10px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Contact
                            </text>
                            <text onClick={()=> window.open(`mailto:dksoft@dksoft.co.kr`)} style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#34b8fd"}}>
                                dksoft@dksoft.co.kr
                            </text>
                        </div>
                    </div>
                </div>

                {/*portfolio*/}
                <div style={{marginTop:"100px", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}>
                        <div style={{width:"100%", height:isMinWindow() ? "30px" : "40px", backgroundColor:"#34b8fd"}}/>
                    </div>
                    <div style={{width:isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{marginLeft:"20px", height:isMinWindow() ? "30px" : "40px", fontFamily:"Pretendard", fontSize:isMinWindow() ? "28px" : "36px", textAlign:"center", color:"#000"}}>
                            Portfolio
                        </text>
                    </div>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>

                {/*portfolio left content - multigym*/}
                <div style={{margin:"100px 0 100px 0", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                    <div style={{width:isMaxWindow() ? "25vw" : isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{width:"400px", height:"22px", fontFamily:"Pretendard", fontSize:"20px", textAlign:"left", color:"#000"}}>
                            멀티짐 프로젝트
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            2023
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            AR Game
                        </text>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Client
                            </text>
                            <text style={{marginLeft:"10px", width:"334px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                키움테크
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Feature
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000", lineHeight:"1.7rem"}}>
                                Unity 엔진 기반 멀티짐내 벽면센서 게임개발<br/>
                                키퍼 VS 슈터<br/>
                                스타러시<br/>
                                퀴즈 러너<br/>
                                슈팅 마스터<br/>
                                빙고 파티
                            </text>
                        </div>
                        {!isMaxWindow() &&
                        <div style={{marginTop:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <img src={multigym} style={{width:isMinWindow() ? "90vw" : "auto"}} alt=""/>
                        </div>
                        }
                    </div>
                    {isMaxWindow() &&
                    <div style={{width:"25vw", display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center"}}>
                        <img src={multigym} style={{width:"22.5vw"}} alt=""/>
                    </div>
                    }
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>
                <RenderDashLine/>

                 {/*portfolio right content - oaktree 2.0*/}
                 <div style={{margin:"100px 0 100px 0", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                    {isMaxWindow() &&
                    <div style={{width:"25vw", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                        <img src={oaktree_2} style={{width:"22.5vw"}} alt=""/>
                    </div>
                    }
                    <div style={{width:isMaxWindow() ? "25vw" : isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{width:"400px", height:"22px", fontFamily:"Pretendard", fontSize:"20px", textAlign:"left", color:"#000"}}>
                            오크트리 2.0 (공용충전사업 모니터링, 관리)
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            2023
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            WEB
                        </text>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Client
                            </text>
                            <text style={{marginLeft:"10px", width:"334px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Design
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz & DKsoft 
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                UX & UI
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Frontend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Backend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Feature
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000", lineHeight:"1.7rem"}}>
                                충전소 및 충전기별 상태 모니터링 및 메타, 이력조회<br/>
                                대시보드, 충전기,DR, 공지및문의, 회원관리<br/>
                                정액/시간별 유금제 관리<br/>
                                결제이력확인 및 정산
                            </text>
                        </div>
                        {!isMaxWindow() &&
                        <div style={{marginTop:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <img src={oaktree_2} style={{width:isMinWindow() ? "90vw" : "auto"}} alt=""/>
                        </div>
                        }
                    </div>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>
                <RenderDashLine/>

                {/*portfolio left content - skyblue public*/}
                <div style={{margin:"100px 0 100px 0", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                    <div style={{width:isMaxWindow() ? "25vw" : isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{width:"400px", height:"22px", fontFamily:"Pretendard", fontSize:"20px", textAlign:"left", color:"#000"}}>
                            스카이블루 공용/비공용 통합작업
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            2023
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            APP ( iOS / Android )
                        </text>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Client
                            </text>
                            <text style={{marginLeft:"10px", width:"334px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Design
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz & DKsoft 
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                UX & UI
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Frontend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Backend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz & DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Feature
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000", lineHeight:"1.7rem"}}>
                                스카이블루(비공용) &#43; 스카이블루 스마트(공용) 차징<br/>
                                내충전기를 등록하고 국민DR 및 플러스DR 참여<br/>
                                스카이블루 충전소를 이용한 국민DR 참여<br/>
                                낙찰된 충전소를 이용한 플러스DR 참여<br/>
                                포인트의 기프트콘 이용<br/>
                                차량 맥어드레스를 이용한 차량인식자동충전 방식 도입<br/>
                                충전상태를 확인하고 제어가능
                            </text>
                        </div>
                        {!isMaxWindow() &&
                        <div style={{marginTop:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <img src={skyblue_public} style={{width:isMinWindow() ? "90vw" : "auto"}} alt=""/>
                        </div>
                        }
                    </div>
                    {isMaxWindow() &&
                    <div style={{width:"25vw", display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center"}}>
                        <img src={skyblue_public} style={{width:"22.5vw"}} alt=""/>
                    </div>
                    }
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>
                <RenderDashLine/>

                {/*portfolio right content - skyblue-smart*/}
                <div style={{margin:"100px 0 100px 0", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                    {isMaxWindow() &&
                    <div style={{width:"25vw", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                        <img src={skyblue_smart} style={{width:"22.5vw"}} alt=""/>
                    </div>
                    }
                    <div style={{width:isMaxWindow() ? "25vw" : isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{width:"400px", height:"22px", fontFamily:"Pretendard", fontSize:"20px", textAlign:"left", color:"#000"}}>
                            스카이블루 스마트차징
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            2023
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            APP ( iOS / Android )
                        </text>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Client
                            </text>
                            <text style={{marginLeft:"10px", width:"334px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Design
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz & DKsoft 
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                UX & UI
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Front
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Backend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz & DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Feature
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000", lineHeight:"1.7rem"}}>
                                스카이블루 공용충전소 전용 앱<br/>
                                결제카드 등록이후 QR스캔으로 자동충전 진행<br/>
                                주변의 스카이블루 충전소 검색 및 상태확인<br/>
                                결제내역 및 포인트미션
                            </text>
                        </div>
                        {!isMaxWindow() &&
                        <div style={{marginTop:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <img src={skyblue_smart} style={{width:isMinWindow() ? "90vw" : "auto"}} alt=""/>
                        </div>
                        }
                    </div>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>
                <RenderDashLine/>

                {/*portfolio left content - green foot*/}
                <div style={{margin:"100px 0 100px 0", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                    <div style={{width:isMaxWindow() ? "25vw" : isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{width:"400px", height:"22px", fontFamily:"Pretendard", fontSize:"20px", textAlign:"left", color:"#000"}}>
                            초록발자국
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            2020 ~ 2021
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            APP
                        </text>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Client
                            </text>
                            <text style={{marginLeft:"10px", width:"334px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                디캐릭
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Feature
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000", lineHeight:"1.7rem"}}>
                                Unity 엔진을 이용한 UI 프라팹 작업
                            </text>
                        </div>
                        {!isMaxWindow() &&
                        <div style={{marginTop:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <img src={greenfoot} style={{width:isMinWindow() ? "90vw" : "auto"}} alt=""/>
                        </div>
                        }
                    </div>
                    {isMaxWindow() &&
                    <div style={{width:"25vw", display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center"}}>
                        <img src={greenfoot} style={{width:"22.5vw"}} alt=""/>
                    </div>
                    }
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>
                <RenderDashLine/>

                 {/*portfolio right content - didim*/}
                 <div style={{margin:"100px 0 100px 0", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                    {isMaxWindow() &&
                    <div style={{width:"25vw", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                        <img src={didim} style={{width:"22.5vw"}} alt=""/>
                    </div>
                    }
                    <div style={{width:isMaxWindow() ? "25vw" : isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{width:"400px", height:"22px", fontFamily:"Pretendard", fontSize:"20px", textAlign:"left", color:"#000"}}>
                            디딤 프로젝트
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            2020 ~ 2021
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            AR GAME
                        </text>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Client
                            </text>
                            <text style={{marginLeft:"10px", width:"334px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Twohands interactive
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Feature
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000", lineHeight:"1.7rem"}}>
                                Unity 엔진을 이용한 디딤플랫폼내 게임개발<br/>
                                UI & Effect FX 제작<br/>
                                게임개발 : STEP RACING, STEP BUBBLE
                            </text>
                        </div>
                        {!isMaxWindow() &&
                        <div style={{marginTop:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <img src={didim} style={{width:isMinWindow() ? "90vw" : "auto"}} alt=""/>
                        </div>
                        }
                    </div>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>
                <RenderDashLine/>

                 {/*portfolio left content - skyblue character*/}
                 <div style={{margin:"100px 0 100px 0", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                    <div style={{width:isMaxWindow() ? "25vw" : isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{width:"400px", height:"22px", fontFamily:"Pretendard", fontSize:"20px", textAlign:"left", color:"#000"}}>
                            SKYBLUE 캐릭터
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            2020 ~ 
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            Character Design
                        </text>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Client
                            </text>
                            <text style={{marginLeft:"10px", width:"334px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Design
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft 
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Feature
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000", lineHeight:"1.7rem"}}>
                                꾸미 외 9종 캐릭터 및 3D모델링개발
                            </text>
                        </div>
                        {!isMaxWindow() &&
                        <div style={{marginTop:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <img src={skyblue_character} style={{width:isMinWindow() ? "90vw" : "auto"}} alt=""/>
                        </div>
                        }
                    </div>
                    {isMaxWindow() &&
                    <div style={{width:"25vw", display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center"}}>
                        <img src={skyblue_character} style={{width:"22.5vw"}} alt=""/>
                    </div>
                    }
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>
                <RenderDashLine/>

                {/*portfolio right content - ulsan energy*/}
                <div style={{margin:"100px 0 100px 0", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                    {isMaxWindow() &&
                    <div style={{width:"25vw", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                        <img src={ulsan_energy} style={{width:"22.5vw"}} alt=""/>
                    </div>
                    }
                    <div style={{width:isMaxWindow() ? "25vw" : isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{width:"400px", height:"22px", fontFamily:"Pretendard", fontSize:"20px", textAlign:"left", color:"#000"}}>
                            울산광역시 분산에너지맵 플랫폼
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            2021 ~ 2022
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            WEB
                        </text>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Client
                            </text>
                            <text style={{marginLeft:"10px", width:"334px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Design
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz & DKsoft 
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                UX & UI
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Frontend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Backend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Feature
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000", lineHeight:"1.7rem"}}>
                                울산시 분산에너지 종합 관제 시스템<br/>
                                운영, 설치현황, 실시간자원현황, 비교 이력 확인
                            </text>
                        </div>
                        {!isMaxWindow() &&
                        <div style={{marginTop:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <img src={ulsan_energy} style={{width:isMinWindow() ? "90vw" : "auto"}} alt=""/>
                        </div>
                        }
                    </div>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>
                <RenderDashLine/>

                {/*portfolio left content - evening*/}
                <div style={{margin:"100px 0 100px 0", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                    <div style={{width:isMaxWindow() ? "25vw" : isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{width:"400px", height:"22px", fontFamily:"Pretendard", fontSize:"20px", textAlign:"left", color:"#000"}}>
                            EVening
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            2021 ~ 2022
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            APP ( iOS / Android )
                        </text>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Client
                            </text>
                            <text style={{marginLeft:"10px", width:"334px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Design
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft 
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                UX & UI
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Frontend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Backend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Feature
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000", lineHeight:"1.7rem"}}>
                                그리드위즈의 EV충전기(AC, DC) 상태확인 및 제어<br/>
                                국민DR, 경/중/최대 부하를 이용한 스마트충전<br/>
                                충전내역확인<br/>
                                차량 자동인증 충전
                            </text>
                        </div>
                        {!isMaxWindow() &&
                        <div style={{marginTop:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <img src={evening} style={{width:isMinWindow() ? "90vw" : "auto"}} alt=""/>
                        </div>
                        }
                    </div>
                    {isMaxWindow() &&
                    <div style={{width:"25vw", display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center"}}>
                        <img src={evening} style={{width:"22.5vw"}} alt=""/>
                    </div>
                    }
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>
                <RenderDashLine/>

                {/*portfolio right content - skyblue*/}
                <div style={{margin:"100px 0 100px 0", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                    {isMaxWindow() &&
                    <div style={{width:"25vw", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                        <img src={skyblue} style={{width:"22.5vw"}} alt=""/>
                    </div>
                    }
                    <div style={{width:isMaxWindow() ? "25vw" : isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{width:"400px", height:"22px", fontFamily:"Pretendard", fontSize:"20px", textAlign:"left", color:"#000"}}>
                            SKYBLUE (통합버전)
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            2021 ~
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            APP ( iOS / Android )
                        </text>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Client
                            </text>
                            <text style={{marginLeft:"10px", width:"334px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Design
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz & DKsoft 
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                UX & UI
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Frontend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Backend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Feature
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000", lineHeight:"1.7rem"}}>
                                일반 충전기 국민DR 연동 및 G포인트 적립<br/>
                                G포인트의 기프티콘구매, 탐나는전으로 전환<br/>
                                제주도 플러스DR 연동<br/>
                                일반충전소 및 플러스DR 충전소 위치 확인<br/>
                                충전내역확인
                            </text>
                        </div>
                        {!isMaxWindow() &&
                        <div style={{marginTop:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <img src={skyblue} style={{width:isMinWindow() ? "90vw" : "auto"}} alt=""/>
                        </div>
                        }
                    </div>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>
                <RenderDashLine/>

                {/*portfolio left content - skyblue dr*/}
                <div style={{margin:"100px 0 100px 0", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                    <div style={{width:isMaxWindow() ? "25vw" : isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{width:"400px", height:"22px", fontFamily:"Pretendard", fontSize:"20px", textAlign:"left", color:"#000"}}>
                            SKYBLUE (수동DR버전)
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            2021
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            APP ( iOS / Android )
                        </text>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Client
                            </text>
                            <text style={{marginLeft:"10px", width:"334px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Design
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz & DKsoft 
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                UX & UI
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Frontend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Backend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz & DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Feature
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000", lineHeight:"1.7rem"}}>
                                일반 충전기 국민DR 연동과 G포인트 적립<br/>
                                일일 충전량을 확인 및 G포인트 적립<br/>
                                AI를 통한 꾸미의 이야기문구와 애니메이션<br/>
                                충전내역확인<br/>
                            </text>
                        </div>
                        {!isMaxWindow() &&
                        <div style={{marginTop:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <img src={skyblue_dr} style={{width:isMinWindow() ? "90vw" : "auto"}} alt=""/>
                        </div>
                        }
                    </div>
                    {isMaxWindow() &&
                    <div style={{width:"25vw", display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center"}}>
                        <img src={skyblue_dr} style={{width:"22.5vw"}} alt=""/>
                    </div>
                    }
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>
                <RenderDashLine/>

                {/*portfolio right content - skyblue poc*/}
                <div style={{margin:"100px 0 100px 0", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                    {isMaxWindow() &&
                    <div style={{width:"25vw", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                        <img src={skyblue_poc} style={{width:"22.5vw"}} alt=""/>
                    </div>
                    }
                    <div style={{width:isMaxWindow() ? "25vw" : isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{width:"400px", height:"22px", fontFamily:"Pretendard", fontSize:"20px", textAlign:"left", color:"#000"}}>
                            SKYBLUE (POC버전)
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            2020 - 2021
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            APP ( iOS / Android )
                        </text>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Client
                            </text>
                            <text style={{marginLeft:"10px", width:"334px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Design
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz & DKsoft 
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                UX & UI
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Frontend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Backend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Feature
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000", lineHeight:"1.7rem"}}>
                                차량의 충전상태 확인가능<br/>
                                충전기 상태확인 및 충전종료 등 충전기제어<br/>
                                국민DR 연동을 통한 G포인트 지급<br/>
                                OBD를 이용한 배터리SOC연동<br/>
                                계약자/사용자 별 화면분기<br/>
                                탄소배출 감축을 통한 곰돌이(꾸미) 친구들 키우기
                            </text>
                        </div>
                        {!isMaxWindow() &&
                        <div style={{marginTop:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <img src={skyblue_poc} style={{width:isMinWindow() ? "90vw" : "auto"}} alt=""/>
                        </div>
                        }
                    </div>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>
                <RenderDashLine/>

                {/*portfolio left content - ocpp*/}
                <div style={{margin:"100px 0 100px 0", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                    <div style={{width:isMaxWindow() ? "25vw" : isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{width:"400px", height:"22px", fontFamily:"Pretendard", fontSize:"20px", textAlign:"left", color:"#000"}}>
                            EV Charging Control System
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            2021 ~
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            FLATFORM
                        </text>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Client
                            </text>
                            <text style={{marginLeft:"10px", width:"334px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Design
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft 
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                UX & UI
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Frontend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Backend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                IMS
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                통합 관리 서버
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                CSMS
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                충전기 통신 서버
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                OCPP
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                충전기 통신 프로토콜
                            </text>
                        </div>
                        {!isMaxWindow() &&
                        <div style={{marginTop:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <img src={evbccs} style={{width:isMinWindow() ? "90vw" : "auto"}} alt=""/>
                        </div>
                        }
                    </div>
                    {isMaxWindow() &&
                    <div style={{width:"25vw", display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center"}}>
                        <img src={evbccs} style={{width:"22.5vw"}} alt=""/>
                    </div>
                    }
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>
                <RenderDashLine/>

                {/*portfolio right content - sang sang app*/}
                <div style={{margin:"100px 0 100px 0", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                    {isMaxWindow() &&
                    <div style={{width:"25vw", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                        <img src={sangsang} style={{width:"22.5vw"}} alt=""/>
                    </div>
                    }
                    <div style={{width:isMaxWindow() ? "25vw" : isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{width:"400px", height:"22px", fontFamily:"Pretendard", fontSize:"20px", textAlign:"left", color:"#000"}}>
                            상상유니브 앱제작
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            2022
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            APP ( iOS / Android )
                        </text>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Client
                            </text>
                            <text style={{marginLeft:"10px", width:"334px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                KT&G
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Frontend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                반응형 Web
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                대학내일
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Backend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                대학내일
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Feature
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000", lineHeight:"1.7rem"}}>
                                반응형으로 제작된 Web을 APP에 구동되도록 제작<br/>
                                푸시(서버포함), 파일첨부등의 디바이스 권한설정<br/>
                                SNS 및 앱 투 앱 연동, 보안, 백버튼 후킹 등
                            </text>
                        </div>
                        {!isMaxWindow() &&
                        <div style={{marginTop:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <img src={sangsang} style={{width:isMinWindow() ? "90vw" : "auto"}} alt=""/>
                        </div>
                        }
                    </div>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>
                <RenderDashLine/>

                {/*portfolio left content - oaktree*/}
                <div style={{margin:"100px 0 100px 0", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                    <div style={{width:isMaxWindow() ? "25vw" : isMinWindow() ? "90vw" : "50vw", display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                        <text style={{width:"400px", height:"22px", fontFamily:"Pretendard", fontSize:"20px", textAlign:"left", color:"#000"}}>
                            Oaktree (충전기 모니터링, 관리)
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            2022 ~
                        </text>
                        <text style={{marginTop:"20px", width:"400px", height:"16px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                            WEB
                        </text>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Client
                            </text>
                            <text style={{marginLeft:"10px", width:"334px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Design
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                Gridwiz & DKsoft 
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                UX & UI
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Frontend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"5px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Backend
                            </text>
                            <text style={{marginLeft:"10px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000"}}>
                                DKsoft
                            </text>
                        </div>
                        <div style={{marginTop:"20px", display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"flex-start"}}>
                            <text style={{width:isMinWindow() ? "70px" : "90px", height:"18px", padding:"2px 0 0 10px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#fff", backgroundColor:"#000"}}>
                                Feature
                            </text>
                            <text style={{marginLeft:isMinWindow() ? "12px" : "20px", width:"370px", fontFamily:"Pretendard", fontSize:"1rem", textAlign:"left", color:"#000", lineHeight:"1.7rem"}}>
                                계정등급별 접근범위<br/>
                                충전기별 상태 모니터링 및 메타, 이력조회<br/>
                                대시보드, DR, 공지 및 문의, 회원관리<br/>
                                충전기 제어 및 펌웨어 업데이트<br/>
                            </text>
                        </div>
                        {!isMaxWindow() &&
                        <div style={{marginTop:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                            <img src={oaktree} style={{width:isMinWindow() ? "90vw" : "auto"}} alt=""/>
                        </div>
                        }
                    </div>
                    {isMaxWindow() &&
                    <div style={{width:"25vw", display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center"}}>
                        <img src={oaktree} style={{width:"22.5vw"}} alt=""/>
                    </div>
                    }
                    <div style={{width:isMinWindow() ? "5vw" : "25vw"}}/>
                </div>

            </>
            : <div style={{height:"1200px"}}/>
            }
        </>
    );
}

export default Main;
